'use client';

import Image from "next/image";
import { useEffect } from 'react';
import useAnimatedRouter from '@/hooks/useAnimatedRouter';
import { ROUTE_WELCOME } from "@/navigation";
import whiteLogoPayout from "@/assets/images/logos/LogoHabacusBianco_Payoff.svg";

// import { getAnalytics, isSupported, logEvent } from "firebase/analytics";

const Landing = () => {
  const { animatedRoute } = useAnimatedRouter();

  // const [analytics, setAnalytics] = useState<any>(null);


  // const initializeAnalitics = async () => {

  //   if (await isSupported()) {
  //     setAnalytics(getAnalytics(firebaseApp))
  //   }
  // }
  // useEffect(() => {
  //   initializeAnalitics()

  // }, [])
  // useEffect(() => {
  //   analytics && logEvent(analytics, 'landing', {
  //     page_title: '/',
  //     page_path: '/'
  //   })
  // }, [analytics])

  useEffect(() => {

    const timer = setTimeout(() => {
      animatedRoute(ROUTE_WELCOME, true);
    }, 2000);

    // Cleanup the timer when the component is unmounted or on re-render
    return () => clearTimeout(timer);
  }, [animatedRoute]);

  return (
    <div className="centeredGradient flex items-center justify-center z-50 fixed  top-0 left-0 right-0 bottom-0">
      <Image
        src={whiteLogoPayout}
        width={600}
        height={600}
        alt="whiteLogoPayout"
      />
      {/* <iframe src="https://giphy.com/embed/IokAQCByJS254Dmw6f" width="100%" height="100%" style={{ position: 'absolute', pointerEvents: "none" }} allowFullScreen></iframe> */}
      {/* <iframe src="https://giphy.com/embed/TvLuZ00OIADoQ" width="100%" height="100%" style={{ position: 'absolute', pointerEvents: "none" }} allowFullScreen></iframe> */}
    </div >
  )
}

export default Landing