export const ROUTE_LANDING = '/';
export const ROUTE_WELCOME = '/welcome';
export const ROUTE_PRIVACY_AND_TERMS_OF_USE = '/privacy_and_terms_of_use';

// ROUTES FROM CONFIG_SLIM
export const ROUTE_STEP_FULL_NAME = '/steps/full_name';
export const ROUTE_STEP_LEAD = '/steps/lead';
export const ROUTE_STEP_BIRTHDAY = '/steps/birthday';
export const ROUTE_STEP_EMAIL = '/steps/email';
export const ROUTE_STEP_DOMICILE = '/steps/domicile';
export const ROUTE_STEP_ITALIAN_RESIDENCE = '/steps/italian_residence';
export const ROUTE_STEP_IS_HS_DONE = '/steps/is_hs_done';
export const ROUTE_STEP_HS_SCHOOL_ENROLLED = '/steps/hs_school_enrolled';
export const ROUTE_STEP_HS_SCHOOL_COURSE_TYPE_ENROLLED = '/steps/hs_school_course_type_enrolled';
export const ROUTE_STEP_HS_YEAR_AT = '/steps/hs_year_at';
export const ROUTE_STEP_HS_SCHOOL = '/steps/hs_school';
export const ROUTE_STEP_HS_SCHOOL_COURSE_TYPE = '/steps/hs_school_course_type';
export const ROUTE_STEP_HS_SCHOOL_GRADUATION_YEAR = '/steps/hs_school_graduation_year';
export const ROUTE_STEP_HS_SCHOOL_GRADE = '/steps/hs_school_grade';
export const ROUTE_STEP_HAS_POST_HS_TITLES = '/steps/has_post_hs_titles';

export const ROUTE_STEP_POST_HS_SCHOOL_TITLE_TYPE_COMPLETED = '/steps/post_hs_school_title_type_completed';
export const ROUTE_STEP_POST_HS_SCHOOL_TITLE_SCHOOL_COMPLETED = '/steps/post_hs_school_title_school_completed';
export const ROUTE_STEP_POST_HS_SCHOOL_COURSE_COMPLETED = '/steps/post_hs_school_course_completed';
export const ROUTE_STEP_POST_HS_SCHOOL_YEAR_AT_COMPLETED = '/steps/post_hs_school_year_at_completed';
export const ROUTE_STEP_POST_HS_SCHOOL_GRADE_COMPLETED = '/steps/post_hs_school_grade_completed';

export const ROUTE_STEP_IS_ENROLLED_POST_HS_SCHOOL = '/steps/is_enrolled_post_hs_school';

export const ROUTE_STEP_POST_HS_SCHOOL_TITLE_TYPE_ENROLLED = '/steps/post_hs_school_title_type_enrolled';
export const ROUTE_STEP_POST_HS_SCHOOL_TITLE_SCHOOL_ENROLLED = '/steps/post_hs_school_title_school_enrolled';
export const ROUTE_STEP_POST_HS_SCHOOL_COURSE_ENROLLED = '/steps/post_hs_school_course_enrolled';
export const ROUTE_STEP_POST_HS_SCHOOL_YEAR_AT_ENROLLED = '/steps/post_hs_school_year_at_enrolled';

export const ROUTE_STEP_SUB_INTERESTS = '/steps/sub_interests';
export const ROUTE_STEP_FUTURE_STEP_WORK = '/steps/future_step_work';
export const ROUTE_STEP_FUTURE_STEP_SCHOOL_TYPE = '/steps/future_step_school_type';
export const ROUTE_STEP_FUTURE_STEP_WHERE = '/steps/future_step_where';

export const ROUTE_STEP_IS_ENROLLED_POST_HS_SCHOOL_FALSE_SURVEY = '/steps/surveys/is_enrolled_post_hs_school_false_survey';
export const ROUTE_STEP_FUTURE_STEP_WHERE_SURVEY = '/steps/surveys/future_step_where_survey';
export const ROUTE_STEP_HS_YEAR_AT_SURVEY = '/steps/surveys/hs_year_at_survey';
export const ROUTE_STEP_POST_HS_SCHOOL_YEAR_AT_ENROLLED_SURVEY = '/steps/surveys/post_hs_school_year_at_enrolled_survey';

export const ROUTE_ADVISORY_FULL_STEP_LIST_FULL = '/advisory_full/step_list_full';
export const ROUTE_ADVISORY_FULL_STEP_HOME_FULL = '/advisory_full/step_home_full';
export const ROUTE_ADVISORY_FULL_STEP_HOME_FULL_SURVEY = '/advisory_full/surveys/step_home_full_survey';

export const ROUTE_ADVISORY_FINANCIAL_STEP_HOME_FINANCIAL = '/advisory_financial/step_home_financial';
export const ROUTE_ADVISORY_FINANCIAL_STEP_CHART = '/advisory_financial/step_chart';
export const ROUTE_ADVISORY_FINANCIAL_STEP_CHART_SURVEY = '/advisory_financial/surveys/step_chart_survey';

export const ROUTE_THANK_YOU = '/advisory_financial/thank_you';

export const routes: Record<string, string> = {
    'ROUTE_LANDING': ROUTE_LANDING,
    'ROUTE_WELCOME': ROUTE_WELCOME,
    'ROUTE_STEP_FULL_NAME': ROUTE_STEP_FULL_NAME,
    'ROUTE_STEP_LEAD': ROUTE_STEP_LEAD,
    'ROUTE_STEP_BIRTHDAY': ROUTE_STEP_BIRTHDAY,
    'ROUTE_STEP_EMAIL': ROUTE_STEP_EMAIL,
    'ROUTE_STEP_DOMICILE': ROUTE_STEP_DOMICILE,
    'ROUTE_STEP_ITALIAN_RESIDENCE': ROUTE_STEP_ITALIAN_RESIDENCE,
    'ROUTE_STEP_IS_HS_DONE': ROUTE_STEP_IS_HS_DONE,
    'ROUTE_STEP_HS_SCHOOL_ENROLLED': ROUTE_STEP_HS_SCHOOL_ENROLLED,
    'ROUTE_STEP_HS_SCHOOL_COURSE_TYPE_ENROLLED': ROUTE_STEP_HS_SCHOOL_COURSE_TYPE_ENROLLED,
    'ROUTE_STEP_HS_YEAR_AT': ROUTE_STEP_HS_YEAR_AT,
    'ROUTE_STEP_HS_SCHOOL': ROUTE_STEP_HS_SCHOOL,
    'ROUTE_STEP_HS_SCHOOL_COURSE_TYPE': ROUTE_STEP_HS_SCHOOL_COURSE_TYPE,
    'ROUTE_STEP_HS_SCHOOL_GRADUATION_YEAR': ROUTE_STEP_HS_SCHOOL_GRADUATION_YEAR,
    'ROUTE_STEP_HS_SCHOOL_GRADE': ROUTE_STEP_HS_SCHOOL_GRADE,
    'ROUTE_STEP_HAS_POST_HS_TITLES': ROUTE_STEP_HAS_POST_HS_TITLES,

    'ROUTE_STEP_POST_HS_SCHOOL_TITLE_TYPE_COMPLETED': ROUTE_STEP_POST_HS_SCHOOL_TITLE_TYPE_COMPLETED,
    'ROUTE_STEP_POST_HS_SCHOOL_TITLE_SCHOOL_COMPLETED': ROUTE_STEP_POST_HS_SCHOOL_TITLE_SCHOOL_COMPLETED,
    'ROUTE_STEP_POST_HS_SCHOOL_COURSE_COMPLETED': ROUTE_STEP_POST_HS_SCHOOL_COURSE_COMPLETED,
    'ROUTE_STEP_POST_HS_SCHOOL_YEAR_AT_COMPLETED': ROUTE_STEP_POST_HS_SCHOOL_YEAR_AT_COMPLETED,
    'ROUTE_STEP_POST_HS_SCHOOL_GRADE_COMPLETED': ROUTE_STEP_POST_HS_SCHOOL_GRADE_COMPLETED,

    'ROUTE_STEP_IS_ENROLLED_POST_HS_SCHOOL': ROUTE_STEP_IS_ENROLLED_POST_HS_SCHOOL,

    'ROUTE_STEP_POST_HS_SCHOOL_TITLE_TYPE_ENROLLED': ROUTE_STEP_POST_HS_SCHOOL_TITLE_TYPE_ENROLLED,
    'ROUTE_STEP_POST_HS_SCHOOL_TITLE_SCHOOL_ENROLLED': ROUTE_STEP_POST_HS_SCHOOL_TITLE_SCHOOL_ENROLLED,
    'ROUTE_STEP_POST_HS_SCHOOL_COURSE_ENROLLED': ROUTE_STEP_POST_HS_SCHOOL_COURSE_ENROLLED,
    'ROUTE_STEP_POST_HS_SCHOOL_YEAR_AT_ENROLLED': ROUTE_STEP_POST_HS_SCHOOL_YEAR_AT_ENROLLED,


    'ROUTE_STEP_SUB_INTERESTS': ROUTE_STEP_SUB_INTERESTS,
    'ROUTE_STEP_FUTURE_STEP_WORK': ROUTE_STEP_FUTURE_STEP_WORK,
    'ROUTE_STEP_FUTURE_STEP_SCHOOL_TYPE': ROUTE_STEP_FUTURE_STEP_SCHOOL_TYPE,
    'ROUTE_STEP_FUTURE_STEP_WHERE': ROUTE_STEP_FUTURE_STEP_WHERE,

    'ROUTE_ADVISORY_FULL_STEP_LIST_FULL': ROUTE_ADVISORY_FULL_STEP_LIST_FULL,
    'ROUTE_ADVISORY_FULL_STEP_HOME_FULL': ROUTE_ADVISORY_FULL_STEP_HOME_FULL,
    'ROUTE_ADVISORY_FINANCIAL_STEP_HOME_FINANCIAL': ROUTE_ADVISORY_FINANCIAL_STEP_HOME_FINANCIAL,
    'ROUTE_ADVISORY_FINANCIAL_STEP_CHART': ROUTE_ADVISORY_FINANCIAL_STEP_CHART,

    'ROUTE_THANK_YOU': ROUTE_THANK_YOU,

    'ROUTE_STEP_IS_ENROLLED_POST_HS_SCHOOL_FALSE_SURVEY': ROUTE_STEP_IS_ENROLLED_POST_HS_SCHOOL_FALSE_SURVEY,
    'ROUTE_STEP_FUTURE_STEP_WHERE_SURVEY': ROUTE_STEP_FUTURE_STEP_WHERE_SURVEY,
    'ROUTE_STEP_HS_YEAR_AT_SURVEY': ROUTE_STEP_HS_YEAR_AT_SURVEY,
    'ROUTE_STEP_POST_HS_SCHOOL_YEAR_AT_ENROLLED_SURVEY': ROUTE_STEP_POST_HS_SCHOOL_YEAR_AT_ENROLLED_SURVEY,
    'ROUTE_ADVISORY_FULL_STEP_HOME_FULL_SURVEY': ROUTE_ADVISORY_FULL_STEP_HOME_FULL_SURVEY,
    'ROUTE_ADVISORY_FINANCIAL_STEP_CHART_SURVEY': ROUTE_ADVISORY_FINANCIAL_STEP_CHART_SURVEY,
}