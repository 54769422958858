import { createNavigation } from 'next-intl/navigation';
import {defineRouting} from 'next-intl/routing';

export type LocaleType = 'it' | 'en';
export const locales = ['it', 'en'] as const;
export const localePrefix = 'always'; // Default

// The `pathnames` object holds pairs of internal
// and external paths, separated by locale.
export const pathnames = {
    // If all locales use the same pathname, a
    // single external path can be provided.
    '/landing': {
        en: '/landing',
        it: '/landing'
    }
};

export const routing = defineRouting({
    // A list of all locales that are supported
    locales: locales,
    localePrefix: localePrefix,
    pathnames: pathnames,
   
    // Used when no locale matches
    defaultLocale: 'it'
});

export const { Link, redirect, usePathname, useRouter } = createNavigation({ locales, localePrefix });