"use client";

import { useRouter } from "@/i18n/routing";
import { useSearchParams } from "next/navigation";
import { createAnswers } from "../utils/client/client_api";

export default function useAnimatedRouter() {
    const router = useRouter();
    const searchParams = useSearchParams();

    // Navigate to the new route
    const animatedRoute = async (url: string, stripQuery = false) => {
        let urlFull = url;
        console.log('searchParams');

        console.log(searchParams);
        console.log(searchParams.keys());
        if (!stripQuery && searchParams.size > 0) {

            if (url.indexOf('?') == -1) {
                console.log('??????');

                console.log(url.indexOf('?'));
                urlFull += '?'
            }
            searchParams.forEach((value, key) => {
                console.log('quadentro foreach');

                console.log(key);
                console.log(value);
                if (!urlFull.endsWith('?')) {
                    urlFull += '&';
                }
                urlFull += key + '=' + value;
            })
        }

        return router.push(urlFull, {});
        
    };
    return { animatedRoute, router, searchParams };
}